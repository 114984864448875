import RozetkapayOrder from '~/modules/acquiring/models/RozetkapayOrder'

const rozetkaPay = {
  computed: {
    model () {
      return RozetkapayOrder
    },
    steps () {
      return {
        preview: 'preview',
        phoneVerification: 'phoneVerification',
        form: 'form',
        clientDocuments: 'clientDocuments',
        financialDocuments: 'financialDocuments',
        signInfo: 'signInfo',
        orderSentInfo: 'orderSentInfo',
        status: 'status'
      }
    },
    statusText () {
      return {
        [this.model.STATUSES_EXTERNAL.userInfoProviding]: 'Info providing',
        [this.model.STATUSES_EXTERNAL.documentsUploading]: 'Documents uploading',
        [this.model.STATUSES_EXTERNAL.initialReview]: 'Initial review',
        [this.model.STATUSES_EXTERNAL.finmonReview]: 'Financial review',
        [this.model.STATUSES_EXTERNAL.covenant]: 'Agreement signing',
        [this.model.STATUSES_EXTERNAL.activation]: 'Activation',
        [this.model.STATUSES_EXTERNAL.activated]: 'Activated',
        [this.model.STATUSES_EXTERNAL.declined]: 'Decline',
        [this.model.STATUSES_EXTERNAL.failed]: 'Error'
      }
    }
  }
}

export default rozetkaPay
