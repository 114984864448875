<template lang="pug">
  div(class="e-file-input-wrapper" :class="{ 'w-100': isResponsive }")
    ValidationProvider(:rules="rules" v-slot="{ errors }" :name="name" ref="validation" :mode="validationMode")
      v-btn(
        color="#161B25"
        :class="{ 'w-100': isResponsive }"
        @click="openFileMenu"
        class="secondary-button"
        outlined
      ) {{ $t(btnText) }}
      v-file-input(
        @change="upload($event)"
        :value="localValue"
        v-bind="$attrs"
        ref="file"
        :accept="accept"
        :multiple="multiple"
        class="e-button-file"
      )
</template>
<script>

export default {
  name: 'EButtonFileUpload',
  props: {
    accept: {
      type: String,
      default: ''
    },
    rules: {
      type: String,
      default: null
    },
    name: {
      type: String,
      default: ''
    },
    validationMode: {
      type: String,
      default: 'aggressive'
    },
    files: {
      type: Array,
      default: null
    },
    multiple: {
      type: Boolean,
      default: false
    },
    btnText: {
      type: String,
      default: 'Download'
    }
  },
  data () {
    return {
      localValue: []
    }
  },
  computed: {
    isResponsive () {
      return this.$vuetify.breakpoint.xs
    }
  },
  watch: {
    localValue (uploadedFiles) {
      if (uploadedFiles.length !== this.files.length) {
        this.$emit('update:files', Array.isArray(uploadedFiles) ? uploadedFiles : [uploadedFiles])
      }
    },
    files (value) {
      if (!value.length) {
        this.localValue = []
      }
    }
  },
  methods: {
    openFileMenu () {
      this.$refs.file.$refs.input.click()
    },
    async validate () {
      return await this.$refs.validation.validate()
    },
    async upload (e) {
      this.$emit('setErrors', [])
      const { valid, errors } = await this.$refs.validation.validate(e)
      if (!valid) {
        this.$emit('setErrors', errors)
        return
      }
      e.forEach((file) => {
        if (!this.localValue.find(f => f.name === file.name)) {
          if (this.multiple) {
            this.localValue = [...this.localValue, file]
          } else {
            this.localValue = [file]
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.e-file-input-wrapper {
  position: relative;
}
.e-button-file {
  position: absolute;
  opacity: 0 !important;
  display: none;

  &-wrapper {
    position: relative;
  }
}
</style>
