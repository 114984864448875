<template lang="pug">
  div(class="hints-block-content")
    h3(v-if="!mobile" class="hints-block-content__title") {{ title }}
    div(v-if="currentStep === steps.preview")
      p(class="mb-2") {{ $t('For this you will need') }}:
      ol
        li(class="mb-2")
          p {{ $t('Fill in the company data') }}
        li(class="mb-2")
          p {{ $t('Download the necessary documents for the bank') }}
        li
          p {{ $t('Sign the application with an electronic signature') }}
    div(v-else-if="currentStep === steps.form")
      p {{ $t('This information is necessary for filling out the bank client questionnaire. Having completed information about your business allows you not to go to the bank') }}
    div(v-else-if="currentStep === steps.clientDocuments")
      p {{ $t('These documents are necessary in order to confirm the identity without visiting the bank') }}
      p {{ $t('Download documents in *.pdf *.jpg *.png format') }}
    div(v-else-if="currentStep === steps.financialDocuments")
      p {{ $t('The presence of uploaded financial documents increases the success of the application by 80% and removes additional questions from the bank. But they are not mandatory') }}
    div(v-else-if="currentStep === steps.signInfo")
      p {{ $t('Good work, all that remains is to sign the completed application') }}
      p {{ $t('To do this, click "Sign" and sign using an electronic key (ECS/KEP)') }}
    div(v-else-if="currentStep === steps.status")
      p
        | Якщо у вас виникли питання, ви можете звернутись до підтримки за номером
        b(class="mx-1") 0 800 330 676
        | або в месенжери
      div(class="support-buttons")
        div(
          v-for="(btn, key) in supportButtons"
          :key="key"
          @click="btn.call"
          class="support-buttons__btn"
        )
          v-btn(
            style="height:40px"
            :id="btn.id"
            v-bind="$attrs"
            depressed
          )
            e-svg-icon(:size="btn.iconSize || 'md'") {{ btn.icon }}
          div()
            h4 {{ btn.label }}
            h3 {{ btn.text }}
</template>

<script>
import ESvgIcon from '~/components/elements/icons/e-svg-icon'

export default {
  name: 'BlockRozetkapayHintContent',
  components: {
    ESvgIcon
  },
  props: {
    steps: {
      type: Object,
      default: () => {}
    },
    currentStep: {
      type: String,
      default: ''
    },
    mobile: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    title () {
      return `💡 ${this.$t('Hint')}`
    },
    supportButtons () {
      return [
        {
          icon: 'telegram',
          label: 'Telegram',
          text: 'checkboxua_bot',
          call: () => this.openLink('https://t.me/checkboxua_bot')
        },
        {
          icon: 'viber',
          label: 'Viber',
          text: 'checkboxua_bot',
          iconSize: 'lg',
          call: () => this.openLink('viber://pa?chatURI=checkboxua')
        }
      ]
    }
  },
  methods: {
    openLink (url) {
      window.open(url, '_blank')
    }
  }
}
</script>

<style scoped lang="scss">
.support-buttons {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 24px;

  &__btn {
    display: flex;
    gap: 16px;
    align-items: center;
    cursor: pointer;

    h4 {
      font-size: 12px;
      color: rgba(22, 27, 37, 0.5);
      font-weight: 300;
      margin-bottom: 4px;
      line-height: 1;
    }

    h3 {
      margin: 0;
      font-size: 14px;
      color: #000;
      font-weight: 400;
      line-height: 1;
    }
  }
}
</style>
