<template lang="pug">
  div(class="block-internet-acquiring-form")
    div(class="dialog__content-flex")
      div(class="dialog__left")
        h3(class="block-internet-acquiring-form__title")
          v-btn(
            v-if="showBackBtn"
            class="back-arrow"
            @click="handleStepBack"
            icon
            small
          )
            e-svg-icon(size="sm") arrow-next
          span {{ isEditing ? $t('Edit pay by link') : $t('Add pay by link') }}
        div(
          v-if="showHint && xsDevice"
          class="mb-6"
        )
          block-acquiring-form-hint(
            mobile
            :currentStep="step"
            :steps="steps"
            acquiringType="internet"
            :isEditing="isEditing"
          )
        block-internet-acquiring-connecting-options(
          v-if="step === steps.optionSelection"
          @connectExisting="step = steps.bankSelection"
          @createNew="handleCreateNewAcquiring"
        )
        m-block-acquiring-bank-selection(
          v-if="step === steps.bankSelection"
          :banks="banks"
          @changeSelectedBank="onChangeSelectedBank"
        )
        block-monobank-qr(
          v-else-if="step === steps.monoQr"
          :close-modal="closeModal"
          @getMonobankQrData="setInternetTerminals"
          @showNextStep="handleStep({ prevStep: steps.monoQr, nextStep: steps.monoSettingsForm })"
        )
        block-monobank-acquiring-settings-form(
          v-else-if="step === steps.monoSettingsForm"
          :terminals="internetTerminals"
          :mono-account-name="monoName"
          type="internet"
          :close-modal="closeModal"
          :table-request="tableRequest"
          :is-editing="isEditing"
          :item="item"
          :show-token-input="showTokenManual"
          @changeMonoAccount="handleStep({ prevStep: steps.bankSelection, nextStep: steps.monoQr })"
        )
        block-easypay-acquiring-settings-form(
          v-else-if="step === steps.easypaySettingsForm"
          :close-modal="closeModal"
          :table-request="tableRequest"
          :is-editing="isEditing"
          :item="item"
        )
        block-rozetkapay-acquiring-settings-form(
          v-else-if="step === steps.rozetkaPaySettingsForm"
          :close-modal="closeModal"
          :table-request="tableRequest"
          :is-editing="isEditing"
          :item="item"
        )
      div(v-if="showHint && !xsDevice" class="dialog__right")
        block-acquiring-form-hint(
          :currentStep="step"
          :steps="steps"
          acquiringType="internet"
          :isEditing="isEditing"
          @setTokenManual="handleManual"
        )
</template>

<script>
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import MBlockAcquiringBankSelection from '~/modules/acquiring/views/m-block-acquiring-bank-selection'
import BlockMonobankQr from '~/modules/acquiring/views/monobank/block-monobank-qr'
import BlockMonobankAcquiringSettingsForm from '~/modules/acquiring/views/monobank/block-monobank-acquiring-settings-form'
import BlockAcquiringSuccess from '~/modules/acquiring/views/block-acquiring-success'
import BlockEasypayAcquiringSettingsForm from '~/modules/acquiring/views/internet-acquiring/block-easypay-acquiring-settings-form'
import BlockAcquiringFormHint from '~/modules/acquiring/views/block-acquiring-form-hint'
import BlockRozetkapayAcquiringSettingsForm from '~/modules/acquiring/views/internet-acquiring/block-rozetkapay-acquiring-settings-form'
import responsive from '~/mixins/pages/responsive'
import contentDialog from '~/mixins/dialogs/contentDialog'
import BlockInternetAcquiringConnectingOptions from '~/modules/acquiring/views/internet-acquiring/block-internet-acquiring-connecting-options'

const easyPayOrganizationsAllowed = [
  '7b1751eb-5278-451e-93ca-46fed33a9388', // Kulyk stage
  '9d57719b-4996-4d23-b2ba-00634d16ccd0', // 3536105154
  '272d9df0-857a-4e6c-bf1e-ec8f41c6182c', // 41685485
  '22fd6b9f-5c99-4562-8bb2-4abf9cae91a9' // 2315400115
]

export default {
  name: 'BlockInternetAcquiringForm',
  components: {
    ESvgIcon,
    BlockMonobankQr,
    BlockMonobankAcquiringSettingsForm,
    BlockAcquiringSuccess,
    BlockEasypayAcquiringSettingsForm,
    MBlockAcquiringBankSelection,
    BlockAcquiringFormHint,
    BlockRozetkapayAcquiringSettingsForm,
    BlockInternetAcquiringConnectingOptions
  },
  mixins: [responsive, contentDialog],
  props: {
    closeModal: {
      type: Function,
      default: () => {}
    },
    tableRequest: {
      type: Function,
      default: () => {}
    },
    isEditing: {
      type: Boolean,
      default: false
    },
    // for Edit
    item: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      // step: 'optionSelection',
      step: 'bankSelection',
      prevStep: null,
      selectedBank: null,
      monobankQrData: null,
      internetTerminals: null,
      monoName: null,
      showTokenManual: false,
      isCreatingNewAcquiring: false
    }
  },
  computed: {
    showHint () {
      return this.step !== this.steps.bankSelection && this.step !== this.steps.optionSelection
    },
    banks () {
      return [
        {
          key: 'mono',
          visible: () => !this.isCreatingNewAcquiring
        },
        {
          key: 'easyPay',
          visible: () => easyPayOrganizationsAllowed.includes(this.$Organization.id)
        },
        {
          key: 'rozetkaPay',
          visible: () => true
        }
      ]
    },
    steps () {
      return {
        optionSelection: 'optionSelection',
        bankSelection: 'bankSelection',
        monoQr: 'monoQr',
        monoSettingsForm: 'monoSettingsForm',
        easypaySettingsForm: 'easypaySettingsForm',
        rozetkaPaySettingsForm: 'rozetkaPaySettingsForm'
      }
    },
    showBackBtn () {
      return [this.steps.monoQr].includes(this.step)
    }
  },
  watch: {
    selectedBank (val) {
      // decide acquiring flow depending on selected bank
      if (val) {
        switch (val) {
          case 'mono':
            this.handleStep({ prevStep: this.steps.bankSelection, nextStep: this.steps.monoQr })
            break
          case 'easyPay':
            this.handleStep({ prevStep: this.steps.bankSelection, nextStep: this.steps.easypaySettingsForm })
            break
          case 'rozetkaPay':
            if (this.isCreatingNewAcquiring) {
              this.closeModal()
              this.contentDialog.open({
                width: '900px',
                component: 'block-rozetkapay-connecting',
                contentNoGutters: true,
                contentFullHeight: true,
                componentProps: {
                  tableRequest: this.tableRequest
                }
              })
            } else {
              this.handleStep({ prevStep: this.steps.bankSelection, nextStep: this.steps.rozetkaPaySettingsForm })
            }
            break
        }
      }
    }
  },
  created () {
    if (this.isEditing) {
      let nextStep = this.steps.easypaySettingsForm
      if (this.item.type === 'monobank') {
        nextStep = this.steps.monoSettingsForm
      } else if (this.item.type === 'rozetkapay') {
        nextStep = this.steps.rozetkaPaySettingsForm
      }
      this.handleStep({ prevStep: null, nextStep })
    }
  },
  methods: {
    handleCreateNewAcquiring () {
      this.step = this.steps.bankSelection
      this.isCreatingNewAcquiring = true
    },
    handleStep ({ prevStep, nextStep }) {
      this.step = nextStep
      this.prevStep = prevStep
    },
    handleStepBack () {
      this.selectedBank = null
      this.handleStep({ prevStep: null, nextStep: this.prevStep })
    },
    setInternetTerminals (data) {
      this.monoName = this._.get(data, 'name')
      this.internetTerminals = this._.filter(data.terminals, terminal => terminal.type === 'internet')
      if (this.internetTerminals && !this.internetTerminals.length) {
        this.closeModal({
          terminals: this.internetTerminals,
          success: false
        })
      }
    },
    onChangeSelectedBank (val) {
      this.selectedBank = val
    },
    handleManual () {
      this.showTokenManual = true
      this.handleStep({ prevStep: this.steps.monoQr, nextStep: this.steps.monoSettingsForm })
    }
  }
}
</script>

<style scoped lang="scss">
.back-arrow {
  transform: rotate(180deg);
  margin-right: 5px;
}
.block-internet-acquiring-form {
  &__title {
    font-weight: 700;
    color: $btn-secondary-color;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 12px;

    @media (min-width: map-get($breakpoints, 'sm')) {
      font-size: 24px;
      margin-bottom: 32px;
      line-height: 32px;
    }
  }
}
</style>
