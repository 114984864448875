<template lang="pug">
  div(class="rozetkapay-financial-documents")
    m-block-uploader(
      v-for="(item, idx) in documents"
      v-if="item.visible"
      :key="idx"
      v-bind="item"
      :validation-field-name="item.title"
      :files.sync="files[item.ref]"
    )
    ValidationProvider(
      ref="termsRef"
      vid="terms"
      name=" "
      mode="lazy"
      rules="is:1"
      v-slot="{ errors }"
      class="mt-3"
      slim
    )
      v-checkbox(
        v-model="terms"
        label="Я даю згоду на обробку та передачу персональних даних, що включає в себе дані ФОП та найменування товару, до ТОВ \"ФК \"ЕВО\" (RozetkaPay)"
        true-value="1"
        false-value="0"
        :error-messages="errors"
        :ripple="false"
      )
</template>

<script>
import MBlockUploader from '~/components/modules/blocks/concrete/m-block-uploader'

export default {
  name: 'BlockRozetkapayClientDocuments',
  components: {
    MBlockUploader
  },
  props: {
    order: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    hasItn: true,
    terms: '1',
    passportType: 'booklet',
    files: {
      annualFinancialReport: [],
      receipt2AnnualFinancialReport: [],
      lastFinancialReport: [],
      receipt2LastFinancialReport: []
    },
    errors: {
      annualFinancialReport: [],
      receipt2AnnualFinancialReport: [],
      lastFinancialReport: [],
      receipt2LastFinancialReport: []
    }
  }),
  computed: {
    accept () {
      return 'image/*,.pdf'
    },
    validationAccept () {
      return 'jpg,jpeg,png,pdf'
    },
    documents () {
      return [
        {
          ...this.commonDocumentAttrs('annualFinancialReport'),
          title: this.$t('Latest annual financial statements'),
          subtitle: `(${this.$t('Tax declaration and Appendix 1')})`
        },
        {
          ...this.commonDocumentAttrs('receipt2AnnualFinancialReport'),
          title: this.$t('Receipt No. 2 for the last annual report')
        },
        {
          ...this.commonDocumentAttrs('lastFinancialReport'),
          title: this.$t('Financial statements for the last reporting period'),
          subtitle: `(${this.$t('Tax declaration')})`
        },
        {
          ...this.commonDocumentAttrs('receipt2LastFinancialReport'),
          title: this.$t('Receipt No. 2 for the last reporting period')
        }
      ]
    }
  },
  methods: {
    commonDocumentAttrs (name) {
      return {
        accept: this.accept,
        btnText: 'Select',
        visible: true,
        ref: name,
        inputErrors: this.errors[name],
        validationMode: 'passive',
        icon: 'file-text-dark',
        rules: 'required|size:5000|ext:' + this.validationAccept
      }
    }
  }
}
</script>

<style scoped lang="scss">
.rozetkapay-financial-documents {
  &::v-deep {
    .uploader {
      align-items: center;
      flex-wrap: wrap;

      @media (min-width: map-get($breakpoints, 'md')) {
        flex-wrap: nowrap;
      }

      .d-flex {
        align-items: center;
      }

      &__subtitle {
        margin-bottom: 0;
        margin-top: 4px;
      }
    }
  }
}
</style>
