<template lang="pug">
  div(class="relative no-print")
    v-btn(
      v-if="!xsDevice && mini"
      class="sidebar__collapse-button sidebar__collapse-button--mini"
      @click="toggleMini"
    )
      v-icon(v-if="savedMini") mdi-chevron-right
      v-icon(v-else class="mr-1") mdi-chevron-left
    v-navigation-drawer(
      v-if="showSidebar"
      v-model="drawer"
      :color="this.$vuetify.theme.currentTheme.drawer"
      :dark="true"
      :back-text="$t('Main menu')"
      :mini-variant="mini"
      :class="{'sidebar': true, 'mobile-active': sidebar }"
      :app="!(mdDevice && !mini)"
      id="app-sidebar"
      permanent
      clipped
      fixed
    )
      template(v-slot:prepend)
        v-list(:height="headHeight" class="py-0")
          v-list-item(dense one-line :class="mini ? 'px-3' : ''")
            v-list-item-content(style="overflow: visible;")
              v-row(align="center" justify="space-between" style="margin-top:3px; width:100%")
                v-col(cols="12" :class="{ 'sidebar__header': true, 'sidebar__header--mini': mini, 'sidebar__header--mobile': xsDevice }")
                  v-btn(
                    v-if="!xsDevice && !mini"
                    class="sidebar__collapse-button"
                    @click="toggleMini"
                  )
                    v-icon(v-if="savedMini") mdi-chevron-right
                    v-icon(v-else class="mr-1") mdi-chevron-left
                  div(class="d-flex align-center")
                    img(
                      :class="{ 'pointer': !allOrganizationsSelected, 'mr-2': !mini }"
                      :src="require('~/assets/images/logo/checkbox-logo-mini.svg')"
                      @click="allOrganizationsSelected ? null : onMenuItemClick('/dashboard')"
                    )
                    v-toolbar-title(
                      v-if="!mini"
                      :class="{ 'pointer': !allOrganizationsSelected, 'pb-0': true }"
                      :title="$nuxt.$options.head.title"
                      @click="allOrganizationsSelected ? null : onMenuItemClick('/dashboard')"
                      style="margin-top: -4px"
                    )
                      v-img(
                        :src="require('~/assets/images/logo/checkbox-logo-white.svg')"
                        :contain="!mini"
                        :height="!mini ? '30px': '40px'"
                        :width="mini ? '20px': '112px'"
                        :position="!mini ? 'left center': '0'"
                        aspect-ratio="1.7"
                      )
                  div(v-if="$User.isOwner")
                    block-notifications-button(
                      :small="mini"
                      @input="onNotificationButtonInput"
                      @openAllNotifications="onMenuItemClick"
                    )
                v-col(
                  v-if="$User.isOwner || $User.isOrganizationUser"
                  cols="12"
                  :style="mini ? 'padding: 0 12px 0;' : 'padding: 0 12px 12px 7px;'"
                )
                  e-input-organization-change(
                    v-if="!mini && $Organization"
                    @change="organizationChange"
                    @focus="menuOutsideItemFocused = true"
                    @blur="menuOutsideItemFocused = false"
                  )
                  block-offline-status(
                    v-if="!_.get($User, 'isAccountant')"
                    :mini="mini"
                  )

      v-list(:class="['pt-0']")
        v-list-item-group
          v-window(v-model="step")
            v-window-item(:value="1")
              template(v-for="(item, i) in items")
                template(v-if="!item.children")
                  v-list-item(
                    v-if="getParam(item.visible, $User, true) && (('model' in item) ? $ability.can(ormActions.read, item.model) : true)"
                    active-class="active"
                    :key="i"
                    class="mt-2 sidebar__menu-item"
                    :to="item.href"
                  )
                    v-list-item-icon(v-if="item.icon" class="d-flex align-center justify-center")
                      component(:is="item.icon.type" v-bind="item.icon.options ? item.icon.options : {}") {{ item.icon.text }}

                    v-list-item-content
                      v-list-item-title(:role="item.href ? 'button' : null") {{ _.upperFirst($t(item.text)) }}
                        v-icon(v-if="item.children && item.children.length" right class="icon-right") mdi-chevron-right
                template(v-if="item.children && item.children.length")
                  v-list-group(
                    :value="true"
                    disabled
                    append-icon=""
                    v-if="showParent(item)"
                    :class="{'sidebar__list-group': true, 'sidebar__list-group--mini': mini, 'sidebar__list-group--highlighted': item.highlight && item.highlight($User) }"
                  )
                    div(
                      v-if="item.highlight && item.highlight($User)"
                      :class="{ 'highlight-block': true, 'highlight-block--mini': mini, 'highlight-block--mobile': xsDevice }"
                    )
                    template(#activator)
                      v-list-item-content(class="py-0" v-if="!mini")
                        v-list-item-title(:role="item.href ? 'button' : null") {{ _.upperFirst($t(item.text)) }}
                    v-list-item(
                      v-for="(item, i) in item.children"
                      v-if="showChildren(item)"
                      :key="i"
                      class="sidebar__menu-item"
                      :to="item.href ? item.href : null"
                      :href="item.link ? item.link : null"
                      :target="item.link ? '_blank' : null"
                      @click="onMenuItemClick()"
                      active-class="active"
                      dense
                    )
                      v-list-item-icon(v-if="item.icon" class="d-flex align-center justify-center")
                        component(:is="item.icon.type" v-bind="item.icon.options ? item.icon.options : {}") {{ item.icon.text }}

                      v-list-item-content
                        v-list-item-title(:role="item.href ? 'button' : null" :style="item.color ? {color: item.color} : {}") {{ _.upperFirst($t(item.text))}}
                          e-svg-icon(class="sidebar__exclamation-icon" v-if="showExclamation(item)") exclamation
                          v-icon(v-if="item.children && item.children.length" right class="icon-right") mdi-chevron-right
                          span(v-if="item.beta && item.beta($Organization)" class="sidebar__note") {{ $t('Beta')}}
                div(
                  v-if="showParent(item)"
                  class="mt-2 mb-2 sidebar__separator"
                  :class="{ 'px-1': mini, 'px-3': !mini }"
                )
                  hr(size="1px" color="#32363f" class="sidebar__separator-line")

              div(class="sidebar__bottom")
                div(v-if="xsDevice" class="px-4 pt-2")
                  v-list-item-title(role="button" class="mb-3") {{ $t('Contacts') }}
                  m-support-buttons(mobile)
                v-list-item(
                  v-if="$Organization && ($User.isOwner || $User.isAdmin || $User.isHeadCashier || $User.isEmergencyCashier || $User.isOwnerWithoutOrganization ) && !allOrganizationsSelected"
                  class="sidebar__menu-item mt-2"
                  active-class="active"
                  to="/dashboard/promocashregisters"
                  dense
                )
                  v-list-item-icon(
                    v-if="mini"
                    class="d-flex justify-center align-center"
                  )
                    e-svg-icon promo
                  v-list-item-content
                    v-list-item-title {{ $t('Action "Replacements of RRO" ') }}
                v-list-item(
                  v-if="$Organization && !$Organization.isDraft && $User.isOwner && !allOrganizationsSelected"
                  class="sidebar__menu-item mt-2"
                  active-class="active"
                  to="/dashboard/referrals"
                  dense
                )
                  v-list-item-icon(
                    v-if="mini"
                    class="d-flex justify-center align-center"
                  )
                    e-svg-icon percent
                  v-list-item-content
                    v-list-item-title(v-if="!mini") {{ $t('Get UAH 100 on the account') }}

                v-list-item(
                  v-if="showEquipmentOrder && $Organization && !$Organization.isDraft && $User.isOwner && !allOrganizationsSelected"
                  class="sidebar__menu-item mt-2"
                  active-class="active"
                  href="https://checkbox.ua/partnery-po-obladnannyu/?utm_source=portal&utm_medium=button"
                  target="_blank"
                  dense
                )
                  v-list-item-icon(
                    v-if="mini"
                    class="d-flex justify-center align-center"
                  )
                    e-svg-icon percent
                  v-list-item-content
                    v-list-item-title(v-if="!mini") {{ $t('Order equipment') }}

                v-list-item(
                  class="sidebar__menu-item mt-1"
                  @click="logout"
                )
                  v-list-item-icon(
                    class="d-flex justify-center align-center"
                    style="margin-top: 9px"
                  )
                    e-svg-icon(size="xmd") logout
                  v-list-item-content
                    v-list-item-title {{ $t('Logout') }}

                div(v-if="!mini" class="mt-2")
                  v-list-item-title
                    latest-version

                div(
                  v-if="superAdminToken"
                  class="sidebar__menu-item--bottom"
                  @click="backToAdmin"
                )
                  v-list-item-icon
                    v-icon mdi-chevron-left
                  span(v-if="!mini") {{ $t('Return to admin') }}

    div(
      v-if="xsDevice"
      class="mobile-menu"
      :class="{'mobile-menu--all-orgs': allOrganizationsSelected }"
    )
      div(
        v-for="(item, index) in mobileMenuItems"
        v-if="item.visible"
        :key="index"
        @click="item.call"
        :class="{'mobile-menu__item': true, 'active': item.active }"
      )
        e-svg-icon {{ item.icon }}
        div {{ $t(item.text) }}
</template>

<script>
import { VIcon } from 'vuetify/lib'
import { actions } from '~/const/global'
import { menuItems } from '~/const/menuItems'
import EFlatIcon from '~/components/elements/icons/e-flat-icon'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import EInputOrganizationChange from '~/components/elements/inputs/concrete/e-input-organization-change'
import Invoices from '~/models/billing/Invoices'
import EProgressCircular from '~/components/elements/progress/e-progress-circular'
import auth from '~/modules/auth/auth'
import responsive from '~/mixins/pages/responsive'
import MSupportButtons from '~/components/modules/m-support-buttons'
import BlockNotificationsButton from '~/modules/notifications/views/components/block-notifications-button'
import taxAuth from '~/mixins/pages/tax-auth'
import TaxInfo from '~/modules/reports/models/TaxInfo'
import multiAccountUsers from '~/mixins/organization/multiAccountUsers'
import BlockOfflineStatus from '~/modules/offline-status/views/components/block-offline-status'
import EmployeesSearch from '~/modules/employees/models/EmployeesSearch'
import LatestVersion from '@/modules/sidebar/views/components/latest-version.vue'

export default {
  components: {
    LatestVersion,
    BlockOfflineStatus,
    MSupportButtons,
    EProgressCircular,
    EInputOrganizationChange,
    EFlatIcon,
    ESvgIcon,
    VIcon,
    BlockNotificationsButton
  },
  mixins: [responsive, taxAuth, multiAccountUsers],
  data: () => ({
    step: 1,
    backText: null,
    drawer: true,
    mini: false,
    savedMini: false,
    sidebar: false,
    showFriendBonus: true,
    menuOutsideItemFocused: false
  }),
  computed: {
    allOrganizationsSelected () {
      return this._.get(this.$User, 'globalAllOrganizationsSelected', false)
    },
    superAdminToken () {
      return auth.getSuperAdminAuthToken()
    },
    headHeight () {
      const isOwner = this.$User.isOwner || this.$User.isOrganizationUser
      if (this.mini) {
        return isOwner ? '157px' : '75px'
      }
      return isOwner ? '162px' : '75px'
    },
    ormActions () {
      return actions
    },
    items () {
      return menuItems
    },
    notMobileDevice () {
      return this.mdDevice && !this.xsDevice
    },
    showSidebar () {
      return !this.xsDevice || this.sidebar
    },
    mobileMenuItems () {
      const sellPath = '/dashboard/createreceipts'
      const returnPath = '/dashboard/refundreceipt'
      return [
        {
          text: 'Selling',
          icon: 'sell-3',
          active: this.$route.path === sellPath && !this.sidebar,
          call: () => this.mobileMenuItemClick(sellPath),
          visible: !this._.get(this.$User, 'globalAllOrganizationsSelected')
        },
        {
          text: 'Return',
          icon: 'selling-3',
          active: this.$route.path === returnPath && !this.sidebar,
          call: () => this.mobileMenuItemClick(returnPath),
          visible: !this._.get(this.$User, 'globalAllOrganizationsSelected')
        },
        {
          text: 'Other',
          icon: 'menu',
          active: this.sidebar,
          call: () => {
            this.sidebar = !this.sidebar
          },
          visible: true
        }
      ]
    },
    showEquipmentOrder () {
      const inImportant = false // false if in important block
      return inImportant
    }
  },
  watch: {
    drawer (val) {
      this.$emit('input', val)
    }
  },
  created () {
    this.updateAllOrganizationSelectedUserData()
    this.getTaxInfo()

    if (!this.$route.path.includes('payment') && this.$Organization) {
      Invoices.api().filter({ 'order[date]': 'desc' }).all({ status: 'invoiced' })
    }

    if (!this.$route.path.includes('employees') && this.$Organization) {
      EmployeesSearch.api().filter({
        limit: 1,
        offset: 1,
        isTest: false,
        mode: 100,
        'order[certificateEnd]': 'asc'
      }).all()
    }

    if (!this.xsDevice) {
      const savedDrawerState = this._.get(this.$localStorageClientData(), 'isDrawerCollapsed')
      if (savedDrawerState === undefined) {
        this.$setLocalStorageClientData({
          isDrawerCollapsed: true
        })
        this.mini = true
        this.savedMini = true
      } else {
        this.mini = savedDrawerState
        this.savedMini = savedDrawerState
      }
    }

    this.showFriendBonus = this._.get(this.$localStorageClientData(), 'showFriendBonus', true)
  },
  mounted () {
    this.onHoverListener()
  },
  methods: {
    onNotificationButtonInput (val) {
      if (!this.xsDevice) {
        this.menuOutsideItemFocused = val
      }
    },
    onHoverListener () {
      if (!this.xsDevice) {
        const drawer = document.querySelector('#app-sidebar')
        const contentWrap = document.querySelector('.v-content__wrap')
        if (drawer) {
          drawer.addEventListener('mouseover', () => {
            if (this.mini && this.savedMini && !this.menuOutsideItemFocused) {
              this.mini = false
            }
          })
          drawer.addEventListener('mouseleave', () => {
            if (!this.mini && this.savedMini && !this.menuOutsideItemFocused) {
              this.mini = true
            }
          })
        }
        if (contentWrap) {
          contentWrap.addEventListener('click', () => {
            if (!this.mini && this.savedMini && !this.menuOutsideItemFocused) {
              this.mini = true
            }
          })
        }
      }
    },
    async getTaxInfo () {
      if (this.$User.isSuperAdmin || this.$User.isOwnerWithoutOrganization || !this.$Organization || this.$Organization?.isDraft) {
        return
      }
      try {
        await TaxInfo.api().getTaxInfo()
      } catch (e) {
        this.$handlers.error(e, this)
      }
    },
    showParent (item) {
      return this.getParam(item.visible, this.$User, true) && (('model' in item) ? this.$ability.can(this.ormActions.read, item.model) : true) && this.hasVisibleChildren(item)
    },
    showChildren (item) {
      return this.getParam(item.visible, this.$User, true) && (('model' in item) ? this.$ability.can(this.ormActions.read, item.model) : true) && this.showOnMobile(item)
    },
    showOnMobile (item) {
      return !(this.xsDevice && (item.href && (item.href.includes('createreceipts') || item.href.includes('refundreceipt'))))
    },
    mobileMenuItemClick (path) {
      this.sidebar = false
      this.$router.push(path)
    },
    onMenuItemClick (route) {
      if (route) {
        this.$router.push(route)
      }
      if (!this.notMobileDevice) {
        this.sidebar = false
      }
    },
    organizationChange () {
      Invoices.api().filter({ 'order[date]': 'desc' }).all({ status: 'invoiced' })
      this.$emit('rerenderContent')
    },
    showExclamation (item) {
      const oneMonthFromNow = new Date()
      oneMonthFromNow.setMonth(oneMonthFromNow.getMonth() + 1)
      const oneMonthBeforeToday = new Date()
      oneMonthBeforeToday.setMonth(oneMonthBeforeToday.getMonth() - 1)

      const isWarningPayment = item.text === 'Payment' && Boolean(Invoices.query().where('status', 'invoiced').first())
      const isWarningEmployees = item.text === 'Employees' && Boolean(EmployeesSearch.query().where((employee) => {
        if (!employee.certificateEnd) {
          return false
        }
        const isActive = employee.mode === 100
        const certificateEnd = new Date(employee.certificateEnd)
        return isActive && certificateEnd >= oneMonthBeforeToday && certificateEnd <= oneMonthFromNow
      }).first())

      return isWarningPayment || isWarningEmployees
    },
    toggleMini () {
      this.savedMini = !this.savedMini
      this.mini = this.savedMini
      this.$setLocalStorageClientData({
        isDrawerCollapsed: this.savedMini
      })
    },
    hasVisibleChildren (item) {
      const children = this._.get(item, 'children', [])
      if (children.length) {
        const visible = children.filter(child => this.showChildren(child))
        return visible.length
      }
      return true
    },
    getParam (param, item, def) {
      if (param === undefined) {
        return def
      }
      if (typeof param === 'function') {
        return param(item, this.$Organization)
      }
      return param
    },
    async backToAdmin () {
      await auth.applyAdminToken()
      this.$router.push('/dashboard/organizations')
    },
    async logout () {
      await auth.logout()
      this.destroyIitGlobalDialog()
      await this.$router.push({ name: 'auth-login' })
    }
  }
}
</script>

<style lang="scss">
.dashboard {
  &__append-list {
    border-radius: 0;
  }
}

.sidebar {
  background: #161b25 !important;
  max-height: 100% !important;
  height: 100% !important;
  overflow: visible !important;

  &__collapse-button {
    position: fixed;
    right: -19px;
    top: 23px;
    width: 20px !important;
    height: 35px !important;
    padding: 0 !important;
    background-color: #161b25 !important;
    border-radius: 0 7px 7px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    cursor: pointer;
    transition: .2s;
    color: #fff !important;

    &--mini {
      left: 56px;
      top: 23px;
      height: 32px !important;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: space-between;
    gap: 15px;
    transition: 0s;

    &--mini {
      flex-direction: column;
    }
  }

  &.mobile-active {
    z-index: 998 !important;
    width: 100% !important;
    height: calc(100% - 53px) !important;
    border-bottom: 1px solid $side-menu-main-text-color !important;
  }

  .v-navigation-drawer__content {
    display: flex;
    flex-direction: column;

    .v-list-item {
      min-height: 36px;
      font-family: $card-body-font-family;

      &__name {
        max-width: 160px;
      }

      &__content {
        padding: 5px 0;
      }

      &__icon {
        margin: 6px 5px 6px 0;

        svg {
          fill: #a5a4bf;
        }
      }
    }

    .favourite {
      position: absolute;
      right: 15px;

      svg {
        fill: #a5a4bf;

        &:hover {
          fill: #fff;
        }
      }
    }
  }

  .v-list-item__title {
    color: $side-menu-main-title-color;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 600;
  }

  .v-list-group {
    position: relative;
  }

  &__exclamation-icon {
    position: absolute;
    width: 17px;
    top: 7px;
    margin-left: 8px;
  }

  &__menu-item {
    &::before {
      background: $focus-color;
    }

    &.active {
      .v-list-item__content {

        .v-list-item__title {
          color: #fff;
        }
      }

      @media (min-width: map-get($breakpoints, 'md')) {
        &::before {
          opacity: 0 !important;
        }
      }
    }

    .v-list-item__title {
      color: $side-menu-main-text-color;
      text-transform: none;
      font-size: 13px;
      overflow: visible;
    }

    .v-icon.icon-right {
      opacity: 0;
    }

    &:hover {
      .v-icon.icon-left {
        transform: translateX(-5px);
        transition: transform .5s, -webkit-transform .5s;
      }

      .v-icon.icon-right {
        opacity: 1;
        animation: opacity1 1s;
        transform: translateX(5px);
        transition: transform .5s, -webkit-transform .5s;
      }
    }

    &--bottom {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 20px;
      color: $side-menu-main-text-color;
      font-size: 13px;
      font-weight: 600;
      cursor: pointer;
      padding: 0 15px;

      &::before {
        opacity: 0 !important;
      }

      &-mini {
        padding: 0 18px;
      }

      .v-ripple__container {
        display: none !important;
      }

      i {
        color: #a1a4a8 !important;
      }

      .v-list-item__icon {
        margin-left: 0 !important;
      }
    }
  }

  &__list-group {
    &--mini {
      .v-list-group__header {
        min-height: auto !important;
      }
    }

    &--highlighted {
      .sidebar__menu-item {
        &::before,
        .v-ripple__container {
          left: 7px;
          width: calc(100% - 14px);
        }

        &:last-child {
          &::before,
          .v-ripple__container {
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
          }
        }
      }
    }
  }

  &__bonus-wrapper {
    padding: 4px 18px;
    background: $color-warning;
    font-size: 11px;
    color: #000;
    font-weight: 600;
    width: 100%;
    white-space: nowrap;

    .v-list-item__icon {
      margin: 0;
    }
  }

  &__note {
    font-size: 0.7rem;
    color: $color-warning;
    position: relative;
    top: -7px;
    left: 6px;

    &--small {
      font-size: 0.65rem;
    }
  }
}

.mobile-menu {
  background: #161b25;
  width: 100%;
  height: 54px;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0;

  &--all-orgs {
    justify-content: space-around;
  }

  &__item {
    color: #fff;
    font-size: 11px;
    text-align: center;
    padding: 0 25px;
    line-height: 14px;
    opacity: 0.6;
    transition: 0.3s;

    &.active {
      opacity: 1;
    }
  }
}

.highlight-block {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  position: absolute;
  height: 100%;
  left: 10px;
  top: 0;
  width: 230px;

  &--mini {
    width: auto;
    left: 11px;
    right: 6px;
  }

  &--mobile {
    right: 10px;
    width: auto;
  }
}
</style>
