<template lang="pug">
  ValidationObserver(:ref="model.entity" slim)
    v-form(@submit.prevent.stop="save")
      v-row()
        v-col(cols="12")
          t-orm-label(v-if="multiple" :item="{ label: { text: 'Delivery details' } }" class="mb-3")
          t-orm-fields(
            v-bind.sync="editedItem"
            :items="fields"
          )
          div(v-if="multiple && showProductFields")
            t-orm-label(:item="{ label: { text: 'Goods' } }" class="mb-3")
            v-row(
              v-for="(value, key, index) in products"
              :key="key"
              no-gutters
            )
              v-col(:cols="Object.keys(products).length > 1 ? 11 : 12")
                t-orm-fields(
                  v-bind.sync="products[key]"
                  :items="productFields"
                )
              v-col(
                v-if="index"
                cols="1"
                class="pl-3"
              )
                v-btn(
                  @click="removeProductFields(key)"
                  icon
                )
                  e-svg-icon(size="mdl") trash
            v-row(no-gutters)
              v-col(cols="auto")
                e-link(@click="addProductFields") + {{ $t('Add goods') }}
              v-col(v-if="isSupply")
                div(class="d-flex justify-end pr-10 mx-3")
                  span(class="mr-2") {{ $t('Total amount') }}:
                  span {{ totalAmount | currencyFormat }}
      v-row(class="mb-4")
        v-col(cols="12")
          t-orm-buttons(:items="buttons" :context="context" :classes="['text-center']")
</template>

<script>
import { v4 as uuidv4 } from 'uuid'
import TOrmButtons from '~/components/templates/orm/t-orm-buttons'
import TOrmFields from '~/components/templates/orm/t-orm-fields'
import validate from '~/mixins/validation/validate'
import checkPropCtx from '~/mixins/methods/checkPropCtx'
import AutocompleteBuilder from '~/components/abstracts/builders/AutocompleteBuilder'
import actions from '~/modules/goods/mixins/actions'
import SuppliersSearch from '~/modules/goods/models/SuppliersSearch'
import Supplies from '~/modules/goods/models/Supplies'
import { formats } from '~/const/global'
import TOrmLabel from '~/components/templates/orm/_includes/t-orm-label'
import AccountingGoods from '~/modules/goods/models/AccountingGoods'
import AccountingGoodsSearch from '~/modules/goods/models/AccountingGoodsSearch'
import AccountingGoodsSettings from '~/modules/goods/models/AccountingGoodsSettings'
import OutletsSearch from '~/models/directories/search/OutletsSearch'
import Outlets from '~/models/directories/Outlets'
import ELink from '~/components/elements/links/e-link'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import currencyFormat from '~/mixins/filters/currencyFormat'
import scrollToFailedValidation from '~/mixins/methods/scrollToFailedValidation'

export default {
  components: {
    ESvgIcon,
    ELink,
    TOrmLabel,
    TOrmButtons,
    TOrmFields
  },
  mixins: [validate, checkPropCtx, actions, currencyFormat, scrollToFailedValidation],
  props: {
    context: {
      type: String,
      default: 'create'
    },
    type: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    item: {
      type: Object,
      default: null
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    model: Supplies,
    editedItem: {
      date: null,
      time: null,
      count: null,
      branch_id: null,
      purchase_price: null,
      supplier_id: null,
      comment: null
    },
    itemLoading: false,
    products: {}
  }),
  computed: {
    useBranches () {
      return this._.get(AccountingGoodsSettings.query().first(), 'use_branches')
    },
    totalAmount () {
      let amount = 0
      this._.each(this.products, (item) => {
        const price = item.purchase_price && parseFloat(item.purchase_price)
        const count = item.count && parseFloat(item.count)
        amount += (count * price)
      })
      return parseFloat(amount.toFixed(2))
    },
    showProductFields () {
      if (!this.useBranches) {
        return true
      }
      return (this.useBranches && this.editedItem.branch_id)
    },
    productFieldModel () {
      return {
        goods_id: null,
        count: null,
        purchase_price: null
      }
    },
    productFields () {
      const fields = [
        new AutocompleteBuilder({
          model: 'goods_id',
          label: 'Name',
          returnObject: true,
          query: (model) => {
            return model.api().filter({
              type: AccountingGoods.TYPES_MAP.good,
              branch_id: this._.get(this.editedItem, 'branch_id.id', null),
              load_count: true,
              use_short_params: true,
              without_total_items: true
            })
          },
          rules: 'required',
          cols: {
            cols: this.isSupply ? 6 : 9
          },
          attrs: {
            key: this._.get(this.editedItem, 'branch_id.id', 'goods_autocomplete')
          },
          emitSearch: false,
          useDefaultSearchFilter: true,
          deleteAllOnSearch: false,
          // cacheItems: true,
          itemsFilter: (items) => {
            return this.isSupply ? items : this._.filter(items, item => item.count)
          }
        }, AccountingGoodsSearch).build(),
        {
          model: 'count',
          component: 'v-text-field',
          provider: {
            name: 'Quantity, od',
            vid: 'count',
            rules: 'required|positive|decimal_number|max_value:2e6'
          },
          attrs: {
            label: 'Quantity, od',
            type: 'number',
            outlined: true,
            min: 0,
            step: 1
          },
          cols: {
            cols: 3
          }
        }
      ]

      if (this.isSupply) {
        fields.push({
          model: 'purchase_price',
          component: 'v-text-field',
          provider: {
            name: 'Purchase price, ₴',
            vid: 'purchase_price',
            rules: 'required|money'
          },
          attrs: {
            label: 'Purchase price, ₴',
            outlined: true
          },
          cols: {
            cols: 3
          }
        })
      }

      return fields
    },
    isSupply () {
      return this.type === 'supply'
    },
    fields () {
      const productBranches = this._.get(this.product, 'branches_info')
      const selectedBranchId = this._.get(this.editedItem, 'branch_id.id')
      const boundedBranch = this._.find(productBranches, i => this._.get(i, 'branch_id') === selectedBranchId) || null
      const showHint = Boolean(selectedBranchId && !boundedBranch)

      return [
        {
          model: 'date',
          component: 'e-input-datetime',
          provider: {
            name: 'Date',
            vid: 'date',
            rules: 'required'
          },
          attrs: {
            label: 'Date',
            outlined: true
          },
          cols: {
            cols: 12,
            md: 6
          },
          fieldVal: () => this.$moment(new Date()).format(formats.dateISO8601)
        },
        {
          model: 'time',
          component: 'e-input-datetime',
          provider: {
            name: 'Time',
            vid: 'time',
            rules: 'required'
          },
          attrs: {
            label: 'Time',
            type: 'time',
            outlined: true
          },
          cols: {
            cols: 12,
            md: 6
          },
          fieldVal: () => {
            const date = new Date()
            const dateMinutes = date.getMinutes()
            const dateHours = date.getHours()
            const minutes = dateMinutes < 10 ? '0' + dateMinutes : dateMinutes
            const hours = dateHours < 10 ? '0' + dateHours : dateHours
            return `${hours}:${minutes}:00`
          }
        },
        {
          cast: val => Math.round((parseFloat(val) * 1000)) || null,
          model: 'count',
          component: 'v-text-field',
          provider: {
            name: this._.get(this.item, 'is_weight') ? 'Quantity, kg' : 'Quantity, od',
            vid: 'count',
            rules: () => (this._.get(this.item, 'is_weight') ? 'required|positive|decimal_number|max_value:2e6' : 'required|positive|integer|max_value:2e6')
          },
          attrs: {
            label: this._.get(this.item, 'is_weight') ? 'Quantity, kg' : 'Quantity, od',
            type: 'number',
            outlined: true,
            min: 0,
            step: 1,
            visible: () => !this.multiple
          },
          cols: {
            cols: 12,
            md: this.isSupply ? 6 : 12
          }
        },
        {
          cast: val => Math.round((parseFloat(val) || 0) * 100),
          model: 'purchase_price',
          component: 'v-text-field',
          provider: {
            name: 'Purchase price, ₴',
            vid: 'purchase_price',
            rules: 'required|money'
          },
          attrs: {
            label: 'Purchase price, ₴',
            outlined: true,
            visible: () => this.isSupply && !this.multiple
          },
          cols: {
            cols: 12,
            md: 6
          }
        },
        new AutocompleteBuilder({
          model: 'supplier_id',
          label: 'Provider',
          query: model => model.api(),
          addEntityBtn: {
            text: '+ ' + this.$t('Add provider'),
            call: async () => await this.addProvider()
          },
          deleteEntityBtn: true,
          rules: 'required',
          visible: () => this.isSupply
        }, SuppliersSearch).build(),
        new AutocompleteBuilder({
          model: 'branch_id',
          label: 'outlet',
          multiple: false,
          itemClass: 'ws-pre',
          cacheItems: true,
          returnObject: true,
          useDefaultSearchFilter: true,
          query: (model) => {
            return model.api().filter({ mode: Outlets.itemModes.checkbox.mode })
          },
          chipText: item => this._.get(item, 'name', '-'),
          itemValue: item => this._.get(item, 'id', null),
          visible: () => this.useBranches,
          hint: showHint ? 'The product is not tied to the selected outlet. If you continue, we will automatically link the product to this outlet' : ''
        }, OutletsSearch).build(),
        {
          model: 'comment',
          component: 'v-text-field',
          provider: {
            name: 'Comment',
            vid: 'comment'
          },
          attrs: {
            label: 'Comment',
            outlined: true
          }
        }
      ]
    },
    buttons () {
      let text = 'Save'
      if (this.$isCreateCtx(this.context)) {
        text = this.isSupply ? 'Add supply' : 'Write off product'
      }
      return [
        {
          text,
          contexts: this.$config.contexts.only('c.u'),
          loading: this.itemLoading || this._.get(this.iitDialog, 'options.loading', false),
          attrs: {
            class: this.isSupply ? 'main-button' : 'main-button color-error',
            type: 'submit'
          }
        }
      ]
    },
    product () {
      return AccountingGoods.query().whereId(this._.get(this.item, 'id')).first()
    }
  },
  watch: {
    'editedItem.branch_id' () {
      this.$set(this.$data, 'products', {})
      AccountingGoodsSearch.deleteAll()
      this.addProductFields()
    }
  },
  async created () {
    this.fillData()

    if (this.item && this.useBranches) {
      await AccountingGoods.api().read(this._.get(this.item, 'id'))
    }

    if (this.multiple) {
      this.addProductFields()
    }
  },
  methods: {
    addProductFields () {
      this.$set(this.products, uuidv4(), Object.assign({}, this.productFieldModel))
    },
    removeProductFields (key) {
      this.$delete(this.products, key)
    },
    fillData () {
      if (this.context === 'update') {
        for (const key in this.item) {
          const keys = Object.keys(this.editedItem)
          if (keys.includes(key)) {
            this.$set(this.editedItem, key, this.item[key])
          }
        }
      }
    },
    close () {
      this.$emit(['dialog', 'close'].join(':'), null)
    },
    getIdsArray (arr) {
      return this._.map(arr, item => this._.get(item, 'id')) || null
    },
    generatePayload () {
      const date = this._.get(this.editedItem, 'date', '') + ' ' + this._.get(this.editedItem, 'time', '')
      const payload = []
      if (!this.multiple) {
        const obj = {
          ...this.editedItem,
          supplier_id: this._.get(this.editedItem, 'supplier_id.id', null),
          branch_id: this.useBranches ? this._.get(this.editedItem, 'branch_id.id', null) : null,
          goods_id: this._.get(this.item, 'id', null),
          date: this.$moment(date).format()
        }
        delete obj.time
        if (!this.isSupply) {
          delete obj.supplier_id
          delete obj.purchase_price
        }
        payload.push(obj)
      } else {
        this._.each(this.products, (item) => {
          const obj = {
            ...item,
            count: Math.round((parseFloat(item.count || 0) * 1000)) || null,
            purchase_price: Math.round((parseFloat(item.purchase_price) * 100)) || null,
            supplier_id: this._.get(this.editedItem, 'supplier_id.id', null),
            branch_id: this.useBranches ? this._.get(this.editedItem, 'branch_id.id', null) : null,
            goods_id: this._.get(item, 'goods_id.id', null),
            comment: this._.get(this.editedItem, 'comment', null),
            date: this.$moment(date).format()
          }
          if (!this.isSupply) {
            delete obj.supplier_id
            delete obj.purchase_price
          }
          payload.push(obj)
        })
      }
      return payload
    },
    updateGoodsModels (goods) {
      this._.each(goods, (item) => {
        AccountingGoods.update({
          where: this._.get(item, 'good.id'),
          data: {
            count: this._.get(item, 'good.count')
          }
        })
      })
    },
    async save () {
      if (!await this.validate()) {
        this.scrollToFailedValidation(this.$refs[this.model.entity]?.$el)
        return false
      }
      try {
        this.itemLoading = true
        let notification = ''
        const payload = this.generatePayload()
        let resGoods = null

        if (this.$isCreateCtx(this.context)) {
          if (this.isSupply) {
            resGoods = this._.get(await Supplies.api().addSupplyToMultipleProducts(payload), 'response.data')
            notification = 'Delivery added successfully'
          } else {
            resGoods = this._.get(await AccountingGoods.api().addWriteOffToMultipleProducts(payload), 'response.data')
            notification = 'Write off added successfully'
          }
        }

        this.updateGoodsModels(resGoods)
        this.$notification.success(this.$t(notification))
        this.close()
      } catch (e) {
        this.$handlers.error(e, this.$refs[this.model.entity])
        this.scrollToFailedValidation(this.$refs[this.model.entity]?.$el)
      } finally {
        this.itemLoading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.hint {
  font-size: 0.75rem;
  line-height: 1.2;
}
</style>
